import { takeLatest, put, call } from "redux-saga/effects";
import toast from "react-hot-toast";
import { auth } from "@helloaudio/auth";

import {
  getFeed,
  updateFeed,
  getMyFeeds,
  exportFeedListenerCSV,
  scheduleFeedRegeneration,
  submitFeedToSpotify,
  finishFeedRollUpload,
  updateFeedSlotRule,
  deleteFeedRoll,
  updateFeedStatus,
  updateFeedGracePeriod,
  deleteFeed,
  duplicateFeed,
  changeFeedType,
  importFeed,
  getImportFeedStatus,
  getFeedRolls,
} from "./FeedSaga";
import { trackUserSignup } from "./TrackingUserSignUpSaga";
import { updateProfile, loadAffiliateCodeFromFirstPromoter } from "./ProfileSaga";
import { getSubscription, updateSubscription, checkUpdateSubscription } from "./SubscriptionSaga";
import { startCustomerPortalSession } from "./StartCustomerPortalSessionSaga";
import { buyAlacarteRemasters } from "./BuyAlacarteRemastersSaga";
import { buyAlacarteAIMinutes } from "./BuyAIMinutesSaga";
import { checkPromoCode } from "./CheckPromoCodeSaga";
import { cancelPlan, loginConnectAccount, showChangePlan } from "./PlanSaga";
import { updateEmailContent } from "./UpdateEmailContentSaga";
import { updateDynaamicContentEnabled, saveDynamicContent } from "./DynamicContentEnabledSaga";
import { uploadImage } from "./UploadImageSaga";
import { sendTestEmail } from "./SendTestEmailSaga";
import {
  getFeedEpisodes,
  getFeedEpisode,
  updateFeedEpisode,
  updateEpisodeStatus,
  transcribeEpisode,
  finishSingleEpisodeUpload,
  massUpdateEpisodes,
  massCopyEpisodes,
} from "./EpisodeSaga";
import {
  addListeners,
  getListeners,
  getSingleListener,
  getListenerCount,
  editListeners,
  deleteListener,
} from "./ListenerSaga";
import { getStepInfo } from "./StepInfoSaga";
import { resendLink, regenerateLink, getUniversalLink } from "./ResendLinkSaga";
import { resetActivation } from "./ResetActivationSaga";
import { getAllUsers } from "./UserSaga";
import { getAllStatistics, getStatistics } from "./StatisticSaga";
import { setOverride, setPublicOverride, setRemasterOverride } from "./OverrideSaga";
import { spoof } from "./SpoofSaga";
import { getCategories } from "./CategorySaga";
import { getMyIntegrations, regenerateApiKey } from "./IntegrationSaga";
import {
  acceptThinkificOauthResult,
  getThinkificEnrollments,
  getThinkificCourses,
  saveThinkificSync,
} from "./ThinkificSaga";
import { getInvoices, getNextInvoice } from "./InvoiceSaga";
import { submitDescriptImport } from "./DescriptImportSaga";
import {
  getMyAction,
  getMyActions,
  createAction,
  deleteAction,
  testAction,
  getMyActionInstances,
} from "./ActionSaga";
import {
  saveEmailIntegration,
  getEmailIntegrations,
  remasterAudioFile,
  trimFileExtensions,
  updateNickname,
  uploadListenerCSV,
  updateSuperlistener,
  getMySuperlisteners,
  getSuperlistener,
  getTagSuggestions,
  acceptZoomOauthResult,
  setAudioInboxEpisodeLoading,
  importURLs,
  addFilesToImportQueue,
  importAudioInboxRecordings,
  getAudioInboxIntegrations,
  deleteIntegration,
  cancelAudioInboxImport,
  getExternalEpisodeList,
  getAudioInboxEpisodes,
} from "./AudioInboxSaga";
import { setRollUseDolby } from "./DolbySaga";
import { successToast } from "./ToastSaga";
import { onboardConnectAccount } from "./OnboardSaga";
import { acceptMailchimpOauthResult } from "./MailChimpSaga";

function safe(sagaFn) {
  return function* (args) {
    try {
      const result = yield call(sagaFn, args);
      return result;
    } catch (error) {
      if (
        ((error.response && error.response.data.error) || error.message).match(/local_user_id/) ||
        (error.response && (error.response.status === 401 || error.response.status === 403))
      ) {
        auth.logout();
        const loginWithRedirect = () =>
          auth.loginWithRedirect(window.location.pathname + window.location.search);
        yield loginWithRedirect();
      } else {
        if (
          (error.response && error.response.data.error) === "Login required" ||
          error.message === "Login required"
        ) {
          toast.error("Login required", { duration: 3000 });
          auth.logout();
        } else {
          yield put({
            type: "SET_ERROR",
            error: (error.response && error.response.data.error) || error.message,
          });
          yield put({ type: "CLEAR_LOADING" });
        }
      }
    }
  };
}

function* sagas() {
  yield takeLatest("TRACK_USER_SIGNUP", safe(trackUserSignup));
  yield takeLatest("UPDATE_PROFILE", safe(updateProfile));
  yield takeLatest("GET_SUBSCRIPTION", safe(getSubscription));
  yield takeLatest("UPDATE_SUBSCRIPTION", safe(updateSubscription));
  yield takeLatest("CHECK_UPDATE_SUBSCRIPTION", safe(checkUpdateSubscription));
  yield takeLatest("START_CUSTOMER_PORTAL_SESSION", safe(startCustomerPortalSession));
  yield takeLatest("BUY_ALACARTE_REMASTERS", safe(buyAlacarteRemasters));
  yield takeLatest("BUY_ALACARTE_AI_MINUTES", safe(buyAlacarteAIMinutes));
  yield takeLatest("CHECK_PROMO_CODE", safe(checkPromoCode));
  yield takeLatest("CANCEL_PLAN", safe(cancelPlan));
  yield takeLatest("SHOW_CHANGE_PLAN", safe(showChangePlan));

  yield takeLatest("UPDATE_FEED", safe(updateFeed));
  yield takeLatest("GET_FEED", safe(getFeed));
  yield takeLatest("GET_MY_FEEDS", safe(getMyFeeds));
  yield takeLatest("UPDATE_FEED_STATUS", safe(updateFeedStatus));
  yield takeLatest("UPDATE_FEED_GRACE_PERIOD", safe(updateFeedGracePeriod));
  yield takeLatest("DELETE_FEED", safe(deleteFeed));
  yield takeLatest("DUPLICATE_FEED", safe(duplicateFeed));
  yield takeLatest("CHANGE_FEED_TYPE", safe(changeFeedType));
  yield takeLatest("IMPORT_FEED", safe(importFeed));
  yield takeLatest("GET_IMPORT_FEED_STATUS", safe(getImportFeedStatus));
  yield takeLatest("GET_FEED_ROLLS", safe(getFeedRolls));
  yield takeLatest("FINISH_ROLL_UPLOAD", safe(finishFeedRollUpload));
  yield takeLatest("UPDATE_FEED_SLOT_RULE", safe(updateFeedSlotRule));
  yield takeLatest("SAVE_DYNAMIC_CONTENT", safe(saveDynamicContent));
  yield takeLatest("SCHEDULE_FEED_REGENERATION", safe(scheduleFeedRegeneration));
  yield takeLatest("DELETE_FEED_ROLL", safe(deleteFeedRoll));
  yield takeLatest("SUBMIT_FEED_TO_SPOTIFY", safe(submitFeedToSpotify));
  yield takeLatest("EXPORT_FEED_LISTENER_CSV", safe(exportFeedListenerCSV));

  yield takeLatest("UPDATE_EMAIL_CONTENT", safe(updateEmailContent));
  yield takeLatest("UPDATE_DYNAMIC_CONTENT_ENABLED", safe(updateDynaamicContentEnabled));
  yield takeLatest("UPLOAD_IMAGE", safe(uploadImage));
  yield takeLatest("GET_FEED_EPISODES", safe(getFeedEpisodes));
  yield takeLatest("GET_FEED_EPISODE", safe(getFeedEpisode));
  yield takeLatest("UPDATE_FEED_EPISODE", safe(updateFeedEpisode));
  yield takeLatest("UPDATE_EPISODE_STATUS", safe(updateEpisodeStatus));
  yield takeLatest("TRANSCRIBE_EPISODE", safe(transcribeEpisode));
  yield takeLatest("FINISH_SINGLE_EPISODE_UPLOAD", safe(finishSingleEpisodeUpload));
  yield takeLatest("MASS_UPDATE_EPISODES", safe(massUpdateEpisodes));
  yield takeLatest("MASS_COPY_EPISODES", safe(massCopyEpisodes));

  yield takeLatest("SEND_TEST_EMAIL", safe(sendTestEmail));
  yield takeLatest("ADD_LISTENERS", safe(addListeners));
  yield takeLatest("GET_LISTENERS", safe(getListeners));
  yield takeLatest("GET_SINGLE_LISTENER", safe(getSingleListener));
  yield takeLatest("GET_LISTENER_COUNT", safe(getListenerCount));
  yield takeLatest("EDIT_LISTENERS", safe(editListeners));
  yield takeLatest("DELETE_LISTENER", safe(deleteListener));

  yield takeLatest("GET_UNIVERSAL_LINK", safe(getUniversalLink));
  yield takeLatest("GET_STEP_INFO", safe(getStepInfo));
  yield takeLatest("RESEND_LINK", safe(resendLink));
  yield takeLatest("REGENERATE_LINK", safe(regenerateLink));
  yield takeLatest("RESET_ACTIVATION", safe(resetActivation));

  yield takeLatest("GET_ALL_USERS", safe(getAllUsers));
  yield takeLatest("GET_ALL_STATISTICS", safe(getAllStatistics));
  yield takeLatest("GET_STATISTICS", safe(getStatistics));

  yield takeLatest("SET_OVERRIDE", safe(setOverride));
  yield takeLatest("SET_PUBLIC_OVERRIDE", safe(setPublicOverride));
  yield takeLatest("SET_REMASTER_OVERRIDE", safe(setRemasterOverride));

  yield takeLatest("SPOOF", safe(spoof));
  yield takeLatest("GET_CATEGORIES", safe(getCategories));
  yield takeLatest("GET_MY_INTEGRATIONS", safe(getMyIntegrations));
  yield takeLatest("GET_EMAIL_INTEGRATIONS", safe(getMyIntegrations));
  // yield takeLatest("DELETE_EMAIL_INTEGRATION", safe(deleteEmailIntegration));
  yield takeLatest("REGENERATE_API_KEY", safe(regenerateApiKey));
  yield takeLatest("ACCEPT_THINKIFIC_OAUTH_RESULT", safe(acceptThinkificOauthResult));
  yield takeLatest("GET_THINKIFIC_ENROLLMENTS", safe(getThinkificEnrollments));
  yield takeLatest("GET_THINKIFIC_COURSES", safe(getThinkificCourses));
  yield takeLatest("SAVE_THINKIFIC_SYNC", safe(saveThinkificSync));

  yield takeLatest("GET_INVOICES", safe(getInvoices));
  yield takeLatest("GET_INVOICE", safe(getNextInvoice));
  yield takeLatest("SUBMIT_DESCRIPT_IMPORT", safe(submitDescriptImport));

  yield takeLatest("GET_MY_ACTION", safe(getMyAction));
  yield takeLatest("GET_MY_ACTIONS", safe(getMyActions));
  yield takeLatest("CREATE_ACTION", safe(createAction));
  yield takeLatest("DELETE_ACTION", safe(deleteAction));
  yield takeLatest("TEST_ACTION", safe(testAction));
  yield takeLatest("GET_MY_ACTION_INSTANCES", safe(getMyActionInstances));

  yield takeLatest("SAVE_EMAIL_INTEGRATION", safe(saveEmailIntegration));
  yield takeLatest("GET_EMAIL_INTEGRATIONS", safe(getEmailIntegrations));
  yield takeLatest("GET_AUDIO_INBOX_INTEGRATIONS", safe(getAudioInboxIntegrations));
  yield takeLatest("DELETE_INTEGRATION", safe(deleteIntegration));
  yield takeLatest("CANCEL_AUDIO_INBOX_IMPORT", safe(cancelAudioInboxImport));
  yield takeLatest("GET_AUDIO_INBOX_FILES", safe(getAudioInboxEpisodes));
  yield takeLatest("SET_LOADING_FILES", safe(setAudioInboxEpisodeLoading));
  yield takeLatest("IMPORT_AUDIO_INBOX_FILES", safe(importAudioInboxRecordings));
  yield takeLatest("IMPORT_FROM_URLS", safe(importURLs));
  yield takeLatest("ADD_FILES_TO_IMPORT_QUEUE", safe(addFilesToImportQueue));
  yield takeLatest("ACCEPT_ZOOM_OAUTH_RESULT", safe(acceptZoomOauthResult));
  yield takeLatest("GET_EXTERNAL_EPISODE_LIST", safe(getExternalEpisodeList));
  yield takeLatest("UPDATE_NICKNAME", safe(updateNickname));
  yield takeLatest("GET_MY_SUPERLISTENERS", safe(getMySuperlisteners));
  yield takeLatest("GET_SUPERLISTENER", safe(getSuperlistener));
  yield takeLatest("GET_TAG_SUGGESTIONS", safe(getTagSuggestions));
  yield takeLatest("UPDATE_SUPERLISTENER", safe(updateSuperlistener));
  yield takeLatest("UPLOAD_LISTENER_CSV", safe(uploadListenerCSV));
  yield takeLatest("REMASTER_AUDIO_FILE", safe(remasterAudioFile));
  yield takeLatest("TRIM_FILE_EXTENSIONS", safe(trimFileExtensions));

  yield takeLatest("SET_ROLL_USE_DOLBY", safe(setRollUseDolby));
  yield takeLatest("SHOW_SUCCESS_TOAST", safe(successToast));

  yield takeLatest("ONBOARD_CONNECT_ACCOUNT", safe(onboardConnectAccount));
  yield takeLatest("LOGIN_CONNECT_ACCOUNT", safe(loginConnectAccount));
  yield takeLatest("ACCEPT_MAILCHIMP_OAUTH", safe(acceptMailchimpOauthResult));
  yield takeLatest(
    "LOAD_AFFILIATE_CODE_FROM_FIRST_PROMOTER",
    safe(loadAffiliateCodeFromFirstPromoter),
  );
}

export default sagas;
