import { Fragment, useId } from "react";
import { RadioGroup } from "@headlessui/react";
import clsx from "clsx";

import { Label } from "@helloaudio/ui";

interface InlineRadioGroupProps<T> {
  className?: string;
  grow?: boolean;
  label?: string;
  helpText?: string;
  name?: string;
  value: T;
  onChange: (value: T) => void;
  optionsClassName?: string;
  disabled?: boolean;
  options: {
    key?: string;
    label: string;
    value: T;
    disabled?: boolean;
  }[];
}
function InlineRadioGroup<T>(props: InlineRadioGroupProps<T>) {
  const { className, optionsClassName, disabled, options, label, value, onChange, grow, helpText } =
    props;
  const id = useId();

  return (
    <RadioGroup className={className} value={value} onChange={onChange}>
      {label && (
        <RadioGroup.Label as={Label} className="tw-mb-2 tw-text-sm tw-font-Montserrat">
          {label}
        </RadioGroup.Label>
      )}
      <fieldset className="tw-flex tw-flex-col tw-flex-wrap tw-gap-2 md:tw-flex-row md:tw-gap-4 md:tw-items-center">
        <legend className="tw-sr-only">Available options</legend>
        {options.map((option, index) => (
          <RadioGroup.Option
            key={option.key !== undefined ? option.key : index}
            value={option.value}
            disabled={disabled || option.disabled}
            className={({ checked, active, disabled }) =>
              clsx(
                "tw-flex tw-items-center tw-h-9 tw-pl-3 tw-pr-3 tw-border tw-border-solid tw-rounded tw-font-Montserrat focus:tw-outline-none tw-w-fit",
                grow && "tw-grow",
                checked && "tw-border-green-500",
                !checked && "tw-border-gray-300",
                active && "tw-ring-2 tw-ring-green-300",
                !disabled && "tw-cursor-pointer",
                disabled && "tw-bg-gray-100",
                optionsClassName,
              )
            }
          >
            {({ checked, disabled }) => (
              <Fragment>
                <div
                  className={clsx(
                    "tw-w-5 tw-h-5 tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-border tw-border-solid tw-rounded-full",
                    checked ? "tw-border-green-500" : "tw-border-gray-300",
                  )}
                >
                  {checked && <div className="tw-w-3 tw-h-3 tw-rounded-full tw-bg-green-500" />}
                </div>
                <label
                  className={clsx(
                    "tw-ml-2 tw-mb-0 tw-block tw-text-sm tw-leading-4 tw-font-medium",
                    !disabled && "tw-text-black tw-cursor-pointer",
                    disabled && "tw-text-gray-500",
                  )}
                >
                  {option.label}
                </label>
              </Fragment>
            )}
          </RadioGroup.Option>
        ))}
      </fieldset>
    </RadioGroup>
  );
}

export default InlineRadioGroup;
